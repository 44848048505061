// Import React Default
// Import utils
import $ from "jquery";
import React, { Component } from 'react';
// Import style
import '../styles/scss/components/tvod.scss';
import icon3g from '../svgs/icon-3g.svg';
import iconCable from '../svgs/icon-cable.svg';
import IconError from '../svgs/icon-error.svg';
// Import images
import iconStartupFail from '../svgs/icon-starttime-fail.svg';
import iconWifi from '../svgs/icon-wifi.svg';



class Tvod extends Component {
	constructor(props){
		super(props);

		this.state = {
			modalStatus: false,
			itemActive: [],
		};
	}

	changeStatusModal = (item) => {
		const { modalStatus } = this.state;
		this.setState({
			modalStatus: !modalStatus,
			itemActive: item,
		});

		setTimeout(function() {
			let CIRCUMFERENCE = 2 * Math.PI * 15;

			$('.modal-tvod .progress-circle-prog').each(function() {
				let prog = $(this);
				let percent = $(this).parent().parent().attr('data-percent');
				var progress = (percent/100 * CIRCUMFERENCE) + ' 999';

				prog.css('stroke-dasharray', progress);
			})
		}, 200);
	}

	EpochtoDate = (epoch) => {
		let today = new Date();

		let yesterday = new Date(today.setDate(today.getDate()-1));
		let date = new Date(0);
		date.setUTCSeconds(epoch / 1000);
		
		let dia  = date.getDate().toString(),
			diaF = (dia.length === 1) ? `0${dia}` : dia;
		let mes  = (date.getMonth()+1).toString(),
			mesF = (mes.length === 1) ? `0${mes}` : mes;
		let ano  = date.getFullYear().toString();
		let time = date.toLocaleTimeString();

		let diffDays = Math.abs(parseInt((date.getTime() - today.getTime()) / (24 * 3600 * 1000)));

		return `${diaF}/${mesF}/${ano} <b class="badge badge-dark">${time}</b>
			${(yesterday.getDate() === date.getDate() 
				&& yesterday.getMonth() === date.getMonth() 
				&& yesterday.getFullYear() === date.getFullYear())  ? '<small>(ontem)</small>' : `<small>(há ${diffDays+1} ${(diffDays+1) === 1 ? 'dia' : 'dias'})</small>`} `;
	}

	render() {
		const { movies } = this.props;
		const { modalStatus, itemActive } = this.state;
		return(
			<>
				<div className="box-itens-tvod">
					{
						movies.map((item, key) => (
							<div className="movie-tvod" key={key}>
								<div className="movie-tvod-category">{item.category}</div>
								<div className="movie-tvod-name">{item.movieName}</div>
								<div className="movie-tvod-price">R$ {item.price.toFixed(2).toLocaleString('pt-BR')}</div>
								<div className="movie-tvod-dates">
									<div className="movie-tvod-dates-row">
										<strong>Compra:</strong> {item.orderDate}
									</div>
									<div className="movie-tvod-dates-row">
										<strong>Expiração:</strong> {item.orderExpires}
									</div>
									<div className="movie-tvod-dates-row">
										<strong>Play:</strong> {(item.firstPlay || 'Não assistido')}
									</div>
								</div>
								<div className={`movie-tvod-status ${item.orderStatus}`}>{item.orderStatus}</div>
								<div
									className={`movie-tvod-details ${(item.sessions.length <= 0 ? 'movie-tvod-details-disable' : '')}`}
									onClick={() => this.changeStatusModal(movies[key])}
								>
									+ Detalhes do Consumo
								</div>
							</div>
						))
					}
				</div>
				{
					modalStatus && (
						<div className="modal-tvod">
							<div className="modal-tvod-content">
								<div
									className="modal-tvod-close"
									onClick={() => this.changeStatusModal()}
								>
									X
								</div>
								<div className="modal-tvod-title">
									Consumo do filme: {itemActive.movieName}
								</div>
								<div className="modal-tvod-itens">
									{
										itemActive.sessions.map((session, index) => (
											<div class="session__item" key={index} data-session={(index) ? index : ''}>
												<div class="session__item-header js-open-tabs">
													<div class="session__group session__group-name">
														<div>
															<label>Nome</label>
															<span>{(session.asset) ? session.asset : ''}</span>
														</div>
														<div class="percent progress js-percent tooltip tooltip-large" data-tooltip="Porcentagem assistida do filme" data-percent={(session.percentWatched > 0) ? session.percentWatched : 0}>
															<svg class="progress-circle" width="50px" height="50px" xmlns="http://www.w3.org/2000/svg">
																<circle class="progress-circle-back"
																		cx="25" cy="25" r="15"></circle>
																<circle class="progress-circle-prog"
																		cx="25" cy="25" r="15"></circle>
															</svg>
															<div class="progress-text" data-progress={session.percentWatched}>{session.percentWatched}%</div>
														</div>
													</div>
													<div class="session__group session__group-duration">
														<label>Duração</label>
														<span>{(session.tags.duration) ? session.tags.duration : '0 min'}</span>
													</div>
													<div class="session__group session__group-model">
														<label>Dispositivo</label>
														<span>{session.tags['Device Marketing Name'] ? session.tags['Device Marketing Name'] : ''}</span>
													</div>
													{
														session.tags.connectionType && (
															<div class="session__group session__group-connectiontype">
																{
																	session.tags.connectionType.toLowerCase() === 'wifi' && (
																		<i class="icon tooltip" data-tooltip="Conexão"><img src={iconWifi} /></i>
																	)
																}
																{
																	session.tags.connectionType.toLowerCase() === 'ethernet' && (
																		<i class="icon tooltip" data-tooltip="Conexão"><img src={iconCable} /></i>
																	)
																}
																{
																	session.tags.connectionType.toLowerCase() === 'cellular' && (
																		<i class="icon tooltip" data-tooltip="Conexão"><img src={icon3g} /></i>
																	)
																}
															</div>
														)
													}
													{
														session.errors && (
															<div class="session__group session__group-errors">
																<div class="error" data-count={session.errors.length}>
																	<i class="error-icon">
																		<img src={IconError} title="erros"/>
																	</i>
																</div>
															</div>
														)
													}
												</div>
												<div class="session__item-content">
													<div class="session__item-contentleft">
														<div class="session__group session__group-browser">
															<label>Browser</label>
															<span>{(session.browser) ? session.browser : ''}</span>
														</div>
														<div class="session__group session__group-os">
															<label>OS</label>
															<span>{(session.tags['Device Operating System Version']) ? session.tags['Device Operating System Version'] : session.tags['Device Operating System Version']}</span>
														</div>
														{
															session.tags['c3.device.ver'] && (
																<div class="session__group session__group-model">
																	<label>Modelo</label>
																	<span>{session.tags['c3.device.ver'] ? session.tags['c3.device.ver'] : ''}</span>
																</div>
															)
														}
														<div class="session__group session__group-version">
															<label>Versão</label>
															<span>{(session.tags.appVersion) ? session.tags.appVersion : ''}</span>
														</div>
														{
															(session.errors) && (
																<div class="session__group session__group-erros--content">
																	{
																		session.isPlayStartFail && (
																			<div class="error-icon error-playstartfail tooltip tooltip-large" data-tooltip="Erro ao iniciar filme"><img src={iconStartupFail} /></div>
																		)
																	}
																</div>
															)
														}
													</div>
													<div class="session__item-contentright">
														{
															session.tags.userProfile && (
																<div class="session__group session__group-profile">
																	<label>perfil:</label>
																	<span>{$(`#${session.tags.userProfile}`).text()}</span>
																</div>
															)
														}
														<div class="session__group session__group-contenttype">
															<label>Tipo de Conteúdo</label>
															<span>{session.tags.contentType ? (session.tags.contentType.toLowerCase() === 'movie' ? 'Filme' : (session.tags.contentType.toLowerCase() === 'maintrailer' ? 'Trailer' : session.tags.contentType.charAt(0).toUpperCase() + session.tags.contentType.slice(1))) : '' }</span>
														</div>
														<div class="session__group session__group-profilekid">
															<label for="">perfil kid:</label>
															<span>{(session.tags.kidProfile) ? ((session.tags.kidProfile !== 'false') ?'sim' : 'não') : ''}</span>
														</div>
														<div class="session__group session__group-ip">
															<label for="">IP:</label>
															<span>{(session.ip) ? session.ip : ''}</span>
														</div>
														<div class="session__group session__group-state">
															<label for="">Estado:</label>
															<span>{session.country} | {session.city}</span>
														</div>
														<div class="session__group session__group-timeexecution">
															<label for="">Tempo de Execução:</label>
															<span>{(session.playTimeMs !== 0) ? Math.floor(session.playTimeMs / 60000) + ' min ' + ((session.playTimeMs % 60000)/1000).toFixed(0) + ' seg': '0 min'}</span>
														</div>
														<div class="session__group session__group-dateexecution">
															<label for="">Data de Execução:</label>
															<span
																dangerouslySetInnerHTML={{__html: session.startTimeMs ? this.EpochtoDate(session.startTimeMs) : '' }}
															/>
														</div>
														<div class="session__group session__group-mediabitrate">
															<label for="">Média de bitrate:</label>
															<span>{(session.avgBitrateKbps && parseFloat(session.avgBitrateKbps) / 1000).toFixed(1)}mb/s</span>
														</div>
													</div>
												</div>
											</div>
										))
									}
								</div>
							</div>
						</div>
					)
				}
			</>
		)
	}
};

export default Tvod;
