import { getToken, isLocalhost } from '../helpers';

const localhost = isLocalhost();


const getTickets = function(email){
	return new Promise((resolve, reject) => {
		fetch(`${localhost}/zendesk/tickets`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({'email' : email})
		}).then(response => {
			if(!response.ok){
				reject({"status": response.status, "msg": response.statusText})

				return
			}

			response.json().then(resolve)
		}).catch(error => console.log(error))
	})
}

export { getTickets };
