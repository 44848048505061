import { getToken, isLocalhost } from '../helpers';

const localhost = isLocalhost()

const accountComcast = function(email, token){
	
	return new Promise((resolve, reject) => {
		return fetch(`${localhost}/comcast/accounts`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'Authorization':  getToken()},
			body: JSON.stringify({'email' : email, 'token': token})
		}).then(response => {

			if (response.status === 403){
				window.location.href = "/login";
				
				localStorage.clear()
				
				reject()

				return
			}
			if(!response.ok){
				reject({"status": response.status, "msg": response.statusText})

				return
			}

			response.text().then(text => {
				if(text === 'Seu Token é inválido ou está expirado.'){
					window.location.href = "/login";
					localStorage.clear()
					
					reject()

					return
				}

				let data = JSON.parse(text);
				if(data.responseCode === 401 && data.description === 'Invalid security token.'){

					reject()
					

					return
				}

				resolve(data)
			})
		}).catch(() => {
			window.location.href = "/login";
					
			localStorage.clear()
		
			reject()

			return})
	})
}


export { accountComcast };
