import { getToken, isLocalhost } from '../helpers';

const localhost = isLocalhost();


const authMassive = function(urn){
	let url = `${localhost}/massive/devices`;
	
	return new Promise((resolve, reject) => {
		fetch(`${url}`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
        		'Accept': 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({'urn': urn})
		}).then(response => {
			if(!response.ok){
				reject({"status": response.status, "msg": response.statusText})

				return
			}


			response.json().then(resolve)
		}).catch(error => console.log(error))
	})
}

const deleteMassive = function(id) {

	return new Promise((resolve, reject) => {
		fetch(`${localhost}/massive/devices`, {
			method: 'delete',
			headers: {
				'Content-Type' : 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({'id': id})
		}).then(response => {
			if(!response.ok){
				reject()
				return
			}

			resolve(response)
		}).catch(error => console.log(error))
	})
}


export { authMassive, deleteMassive };
