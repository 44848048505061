import $ from "jquery";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { getToken, isLocalhost } from '../helpers';
import logoTelecinePortal from '../images/logo-telecine-portal.png';
// styles
import '../styles/scss/admin.scss';
import '../styles/scss/components/user.scss';
// icons
import { ReactComponent as IconEdit } from '../svgs/icon-edit.svg';
import { ReactComponent as IconTrash } from '../svgs/icon-trash.svg';
// components
import Lightbox from './Lightbox';


var CryptoJS = require("crypto-js");

class Admin extends Component {
	constructor(props){
		super(props)
		this.state = {
			users: [],
			email: '',
			tickets: [],
			msg: '',
			changepass: {text: 'alterar senha', disabled: true},
			userEdit: {},
			loading: false,
			empty: false,
			logged: (this.props && this.props.location.state) ? this.props.location.state.logged : false
		}

		this.localhost = isLocalhost()
	}

	componentWillMount(){
		if(!this.state.logged && !this.state.isAdmin){
			this.props.history.push('/login');
			return
		}

		this.getUsers()
	}

	handlechange(propertyName, event){
		let userEdit = this.state.userEdit;

		if(propertyName === 'admin'){
			if(event.target.checked){
				userEdit[propertyName] = 1
			} else {
				userEdit[propertyName] = 0
			}

			this.setState({userEdit: userEdit});
			return
		}

	    userEdit[propertyName] = event.target.value;
	    this.setState({userEdit: userEdit});
	}

	logout(){
		Swal({
		  title: 'Tem certeza que deseja sair?',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  cancelButtonText: 'Cancelar',
		  confirmButtonText: 'Sim, desejo sair!'
		}).then((result) => {
		  if (result.value) {
		    this.props.history.push('/')
			localStorage.clear()
		  }
		})
	}


	getUsers() {
		let self = this;

		// loading
		self.setState({
			loading: true
		})

		fetch(`${this.localhost}/user/list`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': getToken()
			}
		}).then(response => response.json())
			.then(data => {
				let { result } = data;

				if(result) {
					self.setState({users: result})

					// loading
					self.setState({
						loading: false
					})
				}
			}).catch(error => {
				console.log('Error-> ', error)
			})
	}


	listUsers(users){
		users.sort((a,b) => {
			return (a.admin > b.admin) ? -1 : 0
		}).sort((c,d) => {
			return (c.admin !== 1 && c.name) ? c.name.localeCompare(d.name) : ''
		})

		return users.map((user, index) => {
			return (
				<div className="user__item" id={user.id} key={index}>
					<div className="user__group user__group--name">
						<span>{user.name || ''}</span>
					</div>
					<div className="user__group user__group--email">
						<span>{user.email || ''}</span>
					</div>
					<div className="user__group user__group--admin">
						<span>{user.grupo || ''}</span>
					</div>
					<div className="user__group user__group--action">
						<button className="user__button user__button--edit" onClick={() => this.editUserData(user.email)} data-user={user.email}><IconEdit /></button>
						<button className="user__button user__button--exclude" onClick={() => this.deleteUser(user.email)} data-user={user.email}><IconTrash /></button>
					</div>
				</div>
			)
		})
	}
	
	deleteUser(email){
		let self = this;

		Swal({
		  title: 'Tem certeza que deseja excluir este usuário?',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  cancelButtonText: 'Cancelar',
		  confirmButtonText: 'Sim'
		}).then((result) => {
		  if (result.value) {
			$.ajax({
				url: `${this.localhost}/user`,
				type: 'DELETE',
				data: JSON.stringify({"email": email}),
				beforeSend: function (xhr) {
					xhr.setRequestHeader('Authorization', getToken());
					xhr.setRequestHeader('Content-Type', 'application/json');
			  	},
				success: function(response) {
					if(response.success){
						self.getUsers()
					}
				}
			})
		  }
		})
	}
	
	editUser(emailUser, e){
		e.preventDefault()

		let self = this;
	 	
	 	let userEdit = this.state.users.filter((user) => {
				 		return (user.email === emailUser) ? user : ''
				 	})
		console.log(userEdit);

	 	if(this.state.changepass.disabled){
	 		localStorage.setItem('pass', userEdit[0]['password'])
	 		delete userEdit[0]['password']
	 	} else {
	 		if(!userEdit[0]['password'] || userEdit[0]['password'] === ''){
	 			$('#edit_user').find('[name="password"]').css('border', '1px solid red');
	 			return
	 		}

	 		// crypt pass
	 		userEdit[0]['password'] =  CryptoJS.SHA256(userEdit[0]['password']).toString()
	 	}


		$('#edit_user').find('.edit__user-button').text('Atualizando...');

		$.ajax({
			url: `${this.localhost}/user`,
			type: 'POST',
			contentType: 'application/json',
			dataType: 'json',
			data: JSON.stringify(userEdit[0]),
			beforeSend: function (xhr) {
				xhr.setRequestHeader('Authorization', getToken());
		  	},
			success: function(response){
				if(!response.success){
					alert('Erro ao atualizar usuário.')
					return
				}

				$('#edit_user').find('.edit__user-button').text('Atualizar').end().toggleClass('active', false).find('[name="password"]').css('border', 'none')
				self.getUsers()
			},
			error: function(error) {
				alert(error);
			}
		})

	}

	editUserData(email) {
		let userEdit = this.state.users.filter((user) => {
			return (user.email === email) ? user : ''
		});

		this.setState({
			userEdit: userEdit[0]
		});

		$('#edit_user').toggleClass('active', true)
	}

	save(e) {
		e.preventDefault()

		let self = this
		let lightboxNewUser = $('#new_user');

		let name     = lightboxNewUser.find('[name="nome"]').val(),
			email    = lightboxNewUser.find('[name="email"]').val(),
			password = lightboxNewUser.find('[name="password"]').val(),
			isAdmin  = lightboxNewUser.find('[name="isAdmin"]').is(':checked'),
			grupo = lightboxNewUser.find('[name="grupo"]').val(),
			result;
		
		if(!name || !email || !password) {
			this.setState({
				msg: 'Preencha os dados corretamente'
			})

			setTimeout(function(){
				self.setState({
					msg: ''
				})				
			}, 2000)

			return
		}

		
		result = {'name': name, 'email': email, 'password': CryptoJS.SHA256(password).toString(), 'grupo': grupo};


		$.ajax({
			url: `${this.localhost}/user`,
			type: 'POST',
			contentType: 'application/json',
			dataType: 'json',
			data: JSON.stringify(result),
			beforeSend: function (xhr) {
				xhr.setRequestHeader('Authorization', getToken());
		  	},
			success: function(response) {
				if(response.success){
					window.location.reload()
				}
			},
			error: function(error){
				console.log(error)
			}
		});
	}

	changePassword() {
		if(this.state.changepass.text === 'manter senha'){
			this.setState({
				changepass: {text: 'alterar senha', disabled: true}
			})

			return
		}

		this.setState({
			changepass: {text: 'manter senha', disabled: false}
		})
	}

	newUserContent(){
		return (
			<form className="new__user-content" onSubmit={this.save.bind(this)}>
				{(this.state.msg) ? <div className="alert alert-error">{this.state.msg}</div> : ''}
				<div className="form__group">
					<label>Nome</label>
					<input type="text" name="nome"/>
				</div>
				<div className="form__group">
					<label>Email</label>
					<input type="text" name="email"/>
				</div>
				<div className="form__group form__group--double">
					<div className="input__group">
						<label>senha</label>
						<input type="password" name="password"/>
					</div>
					<div className="input__group input__group--checkbox">
						{/* <input type="checkbox" id="isAdmin" name="isAdmin"/> */}
						{/* <label htmlFor="isAdmin">Admin</label> */}
						<label>Tipo de Agente</label>
						<select classname="typeuser" type="select" name="grupo" required>
							<option selected value="">Selecione</option>
							<option value="admin">Admin</option> 
							<option value="N1">N1</option>
							<option value="N2">N2</option>
						</select>
					</div>
				</div>
				<div className="form__group form__group--double form__group--footer">
					<button type="submit">Salvar</button>
				</div>
			</form>
		)
	}

	editUserContent(user){
		return (
			<form className="edit__user-content" onSubmit={this.editUser.bind(this, user.email)}>
				{(this.state.msg) ? <div className="alert alert-error">{this.state.msg || ''}</div> : ''}
				<input type="hidden" name="id" id={user.id || ''}/>
				<div className="form__group">
					<label>Nome</label>
					<input type="text" name="nome" onChange={this.handlechange.bind(this, 'name')} value={user.name || ''}/>
				</div>
				<div className="form__group">
					<label>Email</label>
					<input type="text" name="email" onChange={this.handlechange.bind(this, 'email')} value={user.email || ''}/>
				</div>
				<div className="form__group form__group--double">
					<div className="input__group">
						<div className="input__label">
							<label>senha</label>
							<a className="js-change-password" onClick={this.changePassword.bind(this)}>{this.state.changepass.text}</a>
						</div>

						{(this.state.changepass.disabled) ? <input type="password" name="password" disabled={this.state.changepass.disabled} value='' style={{'border': 'none'}}/> : 
							<input type="password" name="password" onChange={this.handlechange.bind(this, 'password')} />
						} 
					</div>
					<div className="input__group input__group--checkbox">
						<label>Tipo de Agente</label>
						<select
							value={this.state.userEdit.grupo}
							onChange={this.handlechange.bind(this, 'grupo')}
							classname="typeuser" type="select" name="grupo">
							<option value="admin">Admin</option> 
							<option value="N1">N1</option>
							<option value="N2">N2</option>
						</select>
					</div>
				</div>
				<div className="form__group form__group--footer">
					<button className="edit__user-button" type="submit">Atualizar</button>
				</div>
			</form>
		)
	}

	render(){
		let email = localStorage && localStorage.getItem('user');

		return(
			<div className="admin">
				<Lightbox id="new_user" title="Criar nova Conta" content={this.newUserContent()}/>
				{this.state.userEdit ? <Lightbox id="edit_user" title="Editar Usuário" content={this.editUserContent(this.state.userEdit)}/> : null} 

				<header className="header admin__header">
					<div className="header__container container">
						<figure className="header__logo">
							<img src={logoTelecinePortal} alt="Logo Telecine Portal do Agente"/>
						</figure>
						<div className="header__welcome">
							<span className="welcome__title">Bem-vindo {email}</span>
						</div>
						<div className="header__action">
							<Link to={{pathname: '/dashboard', state: {logged: this.state.logged} }} className="header__button header__button--dashboard">Acessar Portal</Link>					
						</div>
						<div className="header__logout">
							<div className="logout">
								<button className="logout__button" onClick={this.logout.bind(this)}>sair</button>
							</div>
						</div>	
					</div>
				</header>
				<main className="admin__content">
					<div className="admin__container container">
						<div className="user">
							<div className="user__count">
								<h3 className="user__count-title" data-count={(!this.state.loading) ? this.state.users.length : '0'}>Usuários</h3>
								<button className="user__button user__button--add" data-lightbox="new_user">novo usuário</button>
							</div>
							<div className="user__header">
								<div className="user__group user__group--name">
									<label>Nome</label>
								</div>
								<div className="user__group user__group--email">
									<label>Email</label>
								</div>
								<div className="user__group user__group--grupo">
									<label>Grupo</label>
								</div>
								<div className="user__group user__group--action">
								</div>
							</div>
							<div className="user__list">
								{(!this.state.loading) ? this.listUsers(this.state.users) : 'Carregando...'}
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default Admin;