export function getToken(){
	let token = localStorage.getItem('token')
	if(!token) return false
	
	return token 
}

export function isLocalhost(){
	
	if (process.env.REACT_APP_PORTAL_AGENTE_API_URL){
		return process.env.REACT_APP_PORTAL_AGENTE_API_URL
	} else {
		return `//localhost:5000`;
	}
	
}