import React, { useEffect, useState } from 'react'
import { getUserpurgeStatus, deleteUserpurgeEvent, postUserpurgeEvent } from '../apis/UserPurge';
import '../styles/scss/components/status.scss';
import { ReactComponent as IconClose } from '../svgs/icon-close.svg';


const Status = ({ guid, method }) => {
	const[lastEventTypeId, setLastEventTypeId] = useState('');
	const[lastEventDate, setLastEventDate] = useState('');
	const[purgeDate, setPurgeDate] = useState('');
	const[indicator, setIndicator] = useState('active');
	const[hasHistoric, setHasHistoric] = useState(false);
	const[buttonAction, setButtonAction] = useState('delete');
	const[buttonDescription, setButtonDescription] = useState('INICIAR PROCESSO DE EXCLUSÃO DE DADOS');
	const[historics, setHistorics] = useState([]); 
	const[classActive, setClassActive] = useState(false);
	const[isMounted, setIsMounted] = useState(false);
	const[isButtonDisabled, setIsButtonDisabled] = useState(false);
	const[hasPermissionToShowButton, setHasPermissionToShowButton] = useState(false);
	const[requestSucessfully, setRequestSucessfully] = useState(true);
	useEffect(() => {	
		let isN1 = window.localStorage.getItem('grupo') === 'N1';
		let isGlobo = (method === 'globo');
		if(!isN1 && !isGlobo){
			setHasPermissionToShowButton(true);
		}
	}, []);
	
	useEffect(() => {	
		if(!isMounted){
			getUserpurgeStatus(guid,setRequestSucessfully).then((data) => {
				extractContent(data.status[0]);	
				setIsMounted(true);	
				setIsButtonDisabled(false);
			}).catch((e) => {
				console.log(e);
			});
		}
	}, [isMounted]);

	const showHideHistoric = () => {
		setClassActive(!classActive);
	}

	const userpurgeEvent = () => {
		if(buttonAction === 'cancel'){
			deleteUserpurgeEvent(guid,setIsButtonDisabled).then((data) => {
			if(data.ok){
				setIsMounted(false);
			}
			}).catch((e) => {
				console.log(e);
			});
		}
		else if(buttonAction === 'delete'){
			postUserpurgeEvent(guid,setIsButtonDisabled).then((data) => {
			if(data.ok){
				setIsMounted(false);
			}
			}).catch((e) => {
				console.log(e);
			});
		}		
	}

	const extractContent = (status) => {

		if(status !== undefined){
			setHasHistoric(true);
			setHistorics(status.historic);
			setLastEventTypeId(status.last_event_type_id);
			setLastEventDate(status.last_event_date);
			
			if(status.last_event_type_id === 1){
				setIndicator('processing');
				setButtonAction('cancel');
				setButtonDescription('CANCELAR PROCESSO DE EXCLUSÃO DE DADOS');		
				setPurgeDate(status.purge_date);		
			}
			else if(status.last_event_type_id === 3){
				setIndicator('active');
				setButtonAction('delete');
				setButtonDescription('INICIAR PROCESSO DE EXCLUSÃO DE DADOS');
			}
		}	
	}



	return ( 
		!requestSucessfully && 
		(
			<div className="status">
				<div>Ocorreu um erro ao recuperar o status.</div>
			</div>
		) 
		||		
		<div className="status">
			<div className="status__content">
				<div>
					<div className="status__description">						
						{lastEventTypeId === 1 
							? <span className="status__info">Processo de exclusão de dados iniciado em <b>{lastEventDate}</b> e previsto para exclusão definitiva em <b>{purgeDate}</b>.</span>
							: <span className="status__info">Não solicitado.</span>
						}				

					{(hasHistoric &&							
						<div className={`status__historic tooltip-balloon-wrapper tooltip-left ${classActive ? 'hover': ''}`} onClick={() => showHideHistoric()}>Ver histórico
							<div className="tooltip-balloon">
								<div className="tooltip-balloon-title">
									<button className="status__button-close" onClick={() => showHideHistoric()}>
										<i className="icon icon-invalidate"><IconClose /></i>
									</button>
								</div>
								{
									(historics.map((historic, index) => {
										return <div key={index} className="status__info tooltip-balloon-body"><span><b>{historic.event_date}</b> - {historic.event_type_description}</span></div>
										})	
									)
								}
							</div>
						</div> 
					)} 
					</div>
					<div className={`status__indicator status__indicator-${indicator}`}></div>						
				</div>
				{(hasPermissionToShowButton &&
					<div className="status__button">
						<button className={`status__content status__delete js-status-${buttonAction}`} disabled={isButtonDisabled} onClick={() => userpurgeEvent()}>{buttonDescription}</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default Status;