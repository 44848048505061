import React, { useState, useEffect } from 'react'
import { resetPasswordDegustacao, getUserTrialDuration } from '../apis/Degustacao';
import '../styles/scss/components/degustacao.scss';


const Degustacao = ({ subscriberId, guid, email }) => {
	const[isButtonDisabled, setIsButtonDisabled] = useState(false);
	const[periodoDegustacao, setPeriodoDegustacao] = useState('Erro ao processar a solicitação');
    const[indicator, setIndicator] = useState('invalid');
    const[isTrialValid, setIsTrialValid] = useState(false);

    useEffect(() => {	
		getUserTrialDuration(subscriberId).then((data) => {
            if(!data.end_trial){
                setPeriodoDegustacao('Não foi possível encontrar o período de degustação');
            }
            else{
                if(data.isTrialValid){
                    setIndicator('active')
                    setIsTrialValid(true)
                }
                setPeriodoDegustacao(`${data.start_trial} a ${data.end_trial}`);            
            }
        }).catch((e) => {
            console.log(e);
        });
	}, [subscriberId]);

	const resetPasswordDegustacaoEvent = () => {
        resetPasswordDegustacao(guid, subscriberId, email, setIsButtonDisabled)
        .then()
        .catch((e) => {
            console.log(e);
        });
    };

	return (
        <div>
            <div className="degustacao__content">
                <h3 className="degustacao-title">Período de Degustação</h3>			
                <div className={`degustacao__indicator degustacao__indicator-${indicator}`}></div>	
                <span className="degustacao__info">{periodoDegustacao}</span>		
            </div> 
            {(
                isTrialValid  &&
                <div className="degustacao__content">
                    <h3 className="degustacao-title">Reset de senha</h3>	
                    <div className="degustacao">			
                        <div className="degustacao__button">
                            <button className={`degustacao__content degustacao__reset`} disabled={isButtonDisabled} onClick={() => resetPasswordDegustacaoEvent()}>Realizar reset de senha</button>
                        </div>
                    </div>
                </div> 
            )}
        </div>
	)
}

export default Degustacao;