import $ from "jquery";
import React, { Component } from 'react';
import { getToken, isLocalhost } from '../helpers';
import '../styles/scss/components/tickets.scss';


class Tickets extends Component {
	constructor(props){
		super(props)
		this.state = {
			target: '',
			comments: [],
			assined: ''
		}
		this.localhost = isLocalhost();
	}
	
	get_ticket_assignee(assignee_id, group_id){
		return new Promise((resolve, reject) => {
			fetch(`${this.localhost}/zendesk/assigneename`, {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json',
					'Authorization':  getToken()
				},
				body: JSON.stringify({'assignee_id': assignee_id, "group_id": group_id})
			}).then(response => {
				if(!response.ok){
					reject({"status": response.status, "msg": response.statusText})
	
					return
				}
	
				response.json().then(resolve)
			}).catch(error => console.log(error))
		})
	}

	list_comments(ticket_id){
		return new Promise((resolve, reject) => {
			fetch(`${this.localhost}/zendesk/comments`, {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json',
					'Authorization':  getToken()
				},
				body: JSON.stringify({'ticket_id' : ticket_id})
			}).then(response => {
				if(!response.ok){
					reject({"status": response.status, "msg": response.statusText})
	
					return
				}
	
				response.json().then(resolve)
			}).catch(error => console.log(error))
		})
	}
	

	openTicket(e, ticket_id, assinee_id, group_id) {
		let button = $(e.target);
		let deviceWrapper = button.closest('.tickets__item-header').siblings('.tickets__item-content');
		this.setState({target: e.target})
		

		if(!deviceWrapper.hasClass('active')){
			let content = $('.active');
			let button_to_close = $('.contas__button-details');
			content.find(".tickets__item-content").toggleClass('active', false);
			button_to_close.text('VER DETALHES');
			this.setState({comments: ''});
			this.setState({assined: ''});

			button.text('FECHAR DETALHES');
			deviceWrapper.toggleClass('active');

			this.get_ticket_assignee(assinee_id, group_id).then(results => {
				this.setState({assined: results.assigned})
			})

			this.list_comments(ticket_id).then(results => {
				this.setState({comments: results.comments})
			})

			return
		}

		button.text('VER DETALHES')
		deviceWrapper.toggleClass('active', false)
	}

	count() {
		let { tickets } = this.props;

		return (<h3 className="tickets__count" data-count={tickets.length}>Tickets</h3>)
	}

	list(){
		const {tickets} = this.props;

		function status(status){
			switch(status){
				case 'new':
					return 'badge-primary';
				case 'open':
					return 'badge-light';
				case 'solved':
					return 'badge-success';
				case 'closed':
					return 'badge-danger';
				case 'hold':
					return 'badge-warning';
				default: 
					return 'badge-dark';
			}
		}

		return tickets.map((ticket, index) => {

			return (
				<div className="tickets__item" key={index}>
					<div className="tickets__item-header">
						<div className="tickets__group tickets__group-id">
							<label>N° Ticket</label>
							<span>{ticket.id}</span>
						</div>
						<div className="tickets__group tickets__group-subject" title={ticket.raw_subject}>
							<label>Assunto</label>
							<span>{ticket.raw_subject}</span>
						</div>
						<div className="tickets__group tickets__group-created">
							<label>Criado em</label>
							<span>{ticket.created_at}</span>
						</div>
						<div className="tickets__group tickets__group-status">
							<label>Status</label>
							<span className={status(ticket.status)}>
								{ticket.status}
							</span>
						</div>
						
						<div className="tickets__group tickets__group-action">
							<a href={'https://telecine.zendesk.com/agent/tickets/' + ticket.id} target="_blank" rel="noopener noreferrer" className="tickets__button tickets__button-openzendesk">abrir no zendesk</a>
							<button className="contas__button-details" onClick={(e) => this.openTicket(e, ticket.id, ticket.assignee_id, ticket.group_id)}>ver detalhes</button>
						</div>
					</div>
					
					<div className="tickets__item-content">
						<div className="ticket_fields ticket_fields-assined">
							<label>Atribuído</label>
							<span className={this.state.assined}>
								{this.state.assined ? this.state.assined : "\u00a0\u00a0"}
							</span>
						</div>
						<div className="ticket_fields ticket_fields-reclass_agente">
							<label>Reclassification</label>
							<span className={ticket.reclass_agente}>
								{ticket.reclass_agente ? ticket.reclass_agente : "\u00a0\u00a0"}
							</span>
						</div>
						<div className="ticket_fields ticket_fields-comentario_agente">
							<label>Comentário do Agente</label>
							<span className={ticket.comentario_agente}>
								{ticket.comentario_agente ? ticket.comentario_agente : "\u00a0\u00a0"}
							</span>
						</div>
						{this.state.comments ? this.state.comments.map(comment => { 
							if (comment.public) {
								return <div className="tickets__group tickets__group-description" key={comment.created_at}>
											<label>{comment.author} {comment.created_at}</label>
											<p>{comment.body}</p>
											
											
											{Array.isArray(comment.attachments) && comment.attachments.length ? 
												<div className='tickets__group attachments'><label>Anexos:</label>
													{comment.attachments.map(attachment => {
														return (<p><a target="_blank" rel="noopener noreferrer" href={attachment.content_url}>{attachment.file_name} </a></p>)
													})}
												</div>: ""}
									</div>	
							}else {
								return <div className="ticket_private_comment ticket_private_comment-description"  key={comment.created_at}>
											<label>{comment.author} {comment.created_at} COMENTÁRIO PRIVADO</label>
											<p>{comment.body}</p>
										
											
											{Array.isArray(comment.attachments) && comment.attachments.length ? 
												<div className='ticket_private_comment attachments'><label>Anexos:</label>
													{comment.attachments.map(attachment => {
														return (<p><a target="_blank" rel="noopener noreferrer" href={attachment.content_url}>{attachment.file_name} </a></p>)
													})}
												</div>: ""}
									</div>	
							}
						}) : this.state.comments}
					</div>
				</div>
			)
		})
	}

	render(){
		return(
			<div className="tickets">
				{this.count()}
				{this.list()}
			</div>
		)
	}
}

export default Tickets;
