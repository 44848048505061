import { getToken, isLocalhost } from '../helpers';
import Swal from 'sweetalert2';
const localhost = isLocalhost();

const resetPasswordDegustacao = async (guid, subscriberId, email, setIsButtonDisabled) => {
	return new Promise(() => {		
		Swal({
			title: 'Tem certeza que deseja resetar a senha do usuário ' + email + '?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Sim',
			allowOutsideClick: false
		  })
		  .then((result) => {
			if (result.value) {
				setIsButtonDisabled(true);
				fetch(`${localhost}/degustacao/reset_password`, {
					method: 'POST',
					headers: {'Content-Type': 'application/json', 'Authorization':  getToken()},
					body: JSON.stringify({
                        'guid' : guid,
                        'subscriber_id': subscriberId
                    })
				})
				.then(response => {
					setIsButtonDisabled(false);
					if(!response.ok){
						Swal({
						  position: 'center',
						  type: 'error',
						  title: 'Ocorreu algum erro ao resetar a senha.',
						  showConfirmButton: false,
						  timer: 1500
						})	
					}
					else{
						return response.json();											
					}
				})
  				.then(data => {		
					if(data){
						Swal({
							position: 'center',
							type: 'warning',
							title: 'A nova senha do usuário é '+data.password,
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'Ok, já enviei para o cliente',
							allowOutsideClick: false
							})	
					}							
				})
				.catch(error => console.log(error))
			}
		})
	})
}

const getUserTrialDuration = async (subscriberId) => {
	return new Promise((resolve) => {
		var url = new URL(`${localhost}/degustacao/trial_details`)
		var params = [['subscriber_id', subscriberId]]
		url.search = new URLSearchParams(params).toString();
		fetch(url, {
			headers: {
				'Content-Type' : 'application/json',
				'Accept': 'application/json',
				'Authorization':  getToken()
			}
		})
		.then(response => resolve(response.json()))
		.catch(error => {
			console.log('Error-> ', error);
		})
	})
}
export { resetPasswordDegustacao, getUserTrialDuration };
