import { getToken, isLocalhost } from '../helpers';

const localhost = isLocalhost();

const authConviva = function(urn){
	let url = `${localhost}/conviva/insights`;

	return new Promise((resolve, reject) => {
		fetch(`${url}`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({'urn' : urn})
		}).then(response => {
			if(!response.ok){
				reject({"status": response.status, "msg": response.statusText})

				return
			}

			response.json().then(resolve)
		}).catch(error => console.log(error))
	})
}


export { authConviva };
