import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../svgs/logo.svg';
import logoTelecine from '../images/logo-telecine.png';
import '../styles/scss/welcome.scss';

class Welcome extends Component {
  access() {
    alert('dsaokdaokdsa')
  }

  render() {
    return (
      <div className="welcome">
        <div className="welcome__header">
          <figure className="welcome__logo-telecine hide-md hide-lg">
              <img src={logoTelecine} alt="Logo Telecine" />
          </figure>
          <figure className="welcome__logo">
              <Logo />
          </figure>
        </div>
        <div className="welcome__body">
          <div className="welcome__access">
            <figure className="welcome__logo-telecine hide-sm">
              <img src={logoTelecine} alt="Logo Telecine" />
            </figure>
            <Link to="/login" className="welcome__access-button">Acessar Portal</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
