import { getToken, isLocalhost } from '../helpers';
const localhost = isLocalhost();

const getConsumptionTvod = async (user) => {
	return new Promise((resolve, reject) => {
		fetch(`${localhost}/tvod/movie_list`, {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json',
				'Authorization':  getToken(),
			},
			body: JSON.stringify({
				'id' : user.userId,
				'guid': user.guid,
			}),
		}).then(response => {
			if(!response.ok){
				reject({"status": response.status, "msg": response.statusText})
				return
			}
			response.json().then(resolve)
		}).catch(error => console.log(error))
	})
}

export { getConsumptionTvod };
