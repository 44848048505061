import $ from "jquery";
import React, { Component } from 'react';
import { authConviva } from '../apis/Conviva';
import { authMassive } from '../apis/Massive';
import '../styles/scss/components/contas.scss';
import '../styles/scss/components/details.scss';
import icon3g from '../svgs/icon-3g.svg';
import iconCable from '../svgs/icon-cable.svg';
import IconError from '../svgs/icon-error.svg';
import iconStartupFail from '../svgs/icon-starttime-fail.svg';
import iconWifi from '../svgs/icon-wifi.svg';


class Details extends Component {
	constructor(props){
		super(props)
		this.state = {data: [], count: 0, msg: ''}
		this.isLocalhost = ~window.location.href.indexOf('localhost') ? true : false
	}

	componentDidMount() {
		let self = this;
		let {target, urn} = this.props;

		let button = $(`[data-urn="${urn}"]`);
		let isGlobo = button.attr('data-globo');
		let perfil = '';

		if(!isGlobo) {
			perfil = self.perfil(urn, isGlobo);
		}

		authConviva(urn).then((data) => {
			if(!data['sessions'] || !data.sessions.length){
				this.setState({count: 0})
				this.setState({msg : 'Não existem sessões para esta conta.'})

				return
			}

			this.setState({count: data.sessions.length})
			this.list(data.sessions)
		}).catch((e) => {
			this.setState({count: 0})
			this.setState({msg : 'Não existem sessões para esta conta.'})
		})
	}

	perfil(urn, isGlobo) {
		let perfil;
		authMassive(urn, 'UserProfiles', isGlobo)
			.then(user => {
				if(!user || !user[0]['profileName']){
					perfil = ''
					return
				}

				perfil = user[0].ProfileName || '';
			}).catch(error => {
				console.log('error ', error)
			})

		return perfil;
	}

	list(sessions) {
		let listSessions = [];

		if(!sessions) return

		function EpochtoDate(epoch){
			let today = new Date();

			let yesterday = new Date(today.setDate(today.getDate()-1));
			let date = new Date(0);
			date.setUTCSeconds(epoch / 1000); // divide by 1000 for milliseconds
			
			let dia  = date.getDate().toString(),
			    diaF = (dia.length === 1) ? `0${dia}` : dia;
			let mes  = (date.getMonth()+1).toString(),
			    mesF = (mes.length === 1) ? `0${mes}` : mes;
			let ano  = date.getFullYear().toString();
			let time = date.toLocaleTimeString();

			let diffDays = Math.abs(parseInt((date.getTime() - today.getTime()) / (24 * 3600 * 1000)));

			return `${diaF}/${mesF}/${ano} <b class="badge badge-dark">${time}</b>
				${(yesterday.getDate() === date.getDate() 
					&& yesterday.getMonth() === date.getMonth() 
					&& yesterday.getFullYear() === date.getFullYear())  ? '<small>(ontem)</small>' : `<small>(há ${diffDays+1} ${(diffDays+1) === 1 ? 'dia' : 'dias'})</small>`} `;
		}

		function Percent(){
			setTimeout(function() {
				let CIRCUMFERENCE = 2 * Math.PI * 15;

				$('.progress-circle-prog').each(function() {
					let prog = $(this);
					let percent = $(this).parent().parent().attr('data-percent');
					var progress = (percent/100 * CIRCUMFERENCE) + ' 999';

					//prog.attr('stroke-dashoffset', dashoffset);
					prog.css('stroke-dasharray', progress);
				})
			}, 2000)
		}

		sessions.map((session, index) => {
		
			let markup = `<div class="session__item" data-session=${(index) ? index : ''}>
							<div class="session__item-header js-open-tabs">
								<div class="session__group session__group-name">
									<div>
										<label>Nome</label>
										<span>${(session.asset) ? session.asset : ''}</span>
									</div>
									<div class="percent progress js-percent tooltip tooltip-large" data-tooltip="Porcentagem assistida do filme" data-percent="${(session.percentWatched > 0) ? session.percentWatched : 0}">
										 <svg class="progress-circle" width="50px" height="50px" xmlns="http://www.w3.org/2000/svg">
										    <circle class="progress-circle-back"
											        cx="25" cy="25" r="15"></circle>
									        <circle class="progress-circle-prog"
									                cx="25" cy="25" r="15"></circle>
									    </svg>
									  	<div class="progress-text" data-progress="${session.percentWatched}">${session.percentWatched}%</div>
									</div>
								</div>
								<div class="session__group session__group-duration">
									<label>Duração</label>
									<span>${(session.tags.duration) ? session.tags.duration : '0 min'}</span>
								</div>
								<div class="session__group session__group-model">
										<label>Dispositivo</label>
										<span>${session.tags['Device Marketing Name'] ? session.tags['Device Marketing Name'] : ''}</span>
								</div>
								
								${session.tags.connectionType ? 
									`<div class="session__group session__group-connectiontype">
										${(session.tags.connectionType.toLowerCase() === 'wifi') ? `<i class="icon tooltip" data-tooltip="Conexão"><img src="${iconWifi}" /></i>` : ''}
										${(session.tags.connectionType.toLowerCase() === 'ethernet') ? `<i class="icon tooltip" data-tooltip="Conexão"><img src="${iconCable}" /></i>` : ''}
										${(session.tags.connectionType.toLowerCase() === 'cellular') ? `<i class="icon tooltip" data-tooltip="Conexão"><img src="${icon3g}" /></i>` : ''}
									</div>` : ''}
								${(session.errors) ? 
								`<div class="session__group session__group-errors">
									<div class="error" data-count="${session.errors.length}">
										<i class="error-icon">
											<img src="${IconError}" title="erros"/>
										</i>
									</div>
								</div>` : ''}
							</div>
							<div class="session__item-content">
								<div class="session__item-contentleft">
									<div class="session__group session__group-browser">
										<label>Browser</label>
										<span>${(session.browser) ? session.browser : ''}</span>
									</div>
									<div class="session__group session__group-os">
										<label>OS</label>
										<span>${(session.tags['Device Operating System Version']) ? session.tags['Device Operating System Version'] : session.tags['Device Operating System Version']}</span>
									</div>
									${session.tags['c3.device.ver'] ? 
									`<div class="session__group session__group-model">
										<label>Modelo</label>
										<span>${session.tags['c3.device.ver'] ? session.tags['c3.device.ver'] : ''}</span>
									</div>`
									: ''}
									<div class="session__group session__group-version">
										<label>Versão</label>
										<span>${(session.tags.appVersion) ? session.tags.appVersion : ''}</span>
									</div>
									${(session.errors) ? `
										<div class="session__group session__group-erros--content">
											${(session.isPlayStartFail) ? `<div class="error-icon error-playstartfail tooltip tooltip-large" data-tooltip="Erro ao iniciar filme"><img src="${iconStartupFail}" /></div>` : ''}
										</div>
									` : ''}
								</div>
								<div class="session__item-contentright">
									${(session.tags.userProfile) ? `<div class="session__group session__group-profile">
										<label for="">perfil:</label>
										<span>${$(`#${session.tags.userProfile}`).html()}</span>
									</div>` : ''}
									<div class="session__group session__group-contenttype">
										<label>Tipo de Conteúdo</label>
										<span>${session.tags.contentType ? (session.tags.contentType.toLowerCase() === 'movie' ? 'Filme' : (session.tags.contentType.toLowerCase() === 'maintrailer' ? 'Trailer' : session.tags.contentType.charAt(0).toUpperCase() + session.tags.contentType.slice(1))) : '' }</span>
									</div>
									<div class="session__group session__group-profilekid">
										<label for="">perfil kid:</label>
										<span>${(session.tags.kidProfile) ? ((session.tags.kidProfile !== 'false') ?'sim' : 'não') : ''}</span>
									</div>
									<div class="session__group session__group-ip">
										<label for="">IP:</label>
										<span>${(session.ip) ? session.ip : ''}</span>
									</div>
									<div class="session__group session__group-state">
										<label for="">Estado:</label>
										<span>${session.country} | ${session.city}</span>
									</div>
									<div class="session__group session__group-timeexecution">
										<label for="">Tempo de Execução:</label>
										<span>${(session.playTimeMs !== 0) ? Math.floor(session.playTimeMs / 60000) + ' min ' + ((session.playTimeMs % 60000)/1000).toFixed(0) + ' seg': '0 min'}</span>
									</div>
									<div class="session__group session__group-dateexecution">
										<label for="">Data de Execução:</label>
										<span>${(session.startTimeMs) ? EpochtoDate(session.startTimeMs) : ''}</span>
									</div>
									<div class="session__group session__group-mediabitrate">
										<label for="">Média de bitrate:</label>
										<span>${(session.avgBitrateKbps && parseFloat(session.avgBitrateKbps) / 1000).toFixed(1)}mb/s</span>
									</div>
								</div>
							</div>
						</div>`;
			listSessions.push(markup)
			return markup;
		}).join('')

		this.setState({data: listSessions.join('')})

		Percent()
	}

	count() {
		return (<h3 className="details__count" data-count={this.state.count}>Sessões</h3>)
	}

	render() {
		return (
			<div className="details">
				<div className="details__header">{this.count()}</div>
				<div className="details__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>
			</div>
		)
	}
}

export default Details;