import { getToken, isLocalhost } from '../helpers';
import Swal from 'sweetalert2';
const localhost = isLocalhost();

const getUserpurgeStatus = async (guid, setRequestSucessfully) => {
	return new Promise((resolve) => {
		var url = new URL(`${localhost}/userpurge/status`)
		var params = [['guid', guid]]
		url.search = new URLSearchParams(params).toString();
		fetch(url, {
			headers: {
				'Content-Type' : 'application/json',
				'Accept': 'application/json',
				'Authorization':  getToken()
			}
		})
		.then(response => response.json())
		.then(result => {
			resolve(result)
		})
		.catch(error => {
			setRequestSucessfully(false);
			console.log('Error-> ', error);
		})
	})
}

const deleteUserpurgeEvent = async (guid,setIsButtonDisabled) => {
	return new Promise((resolve) => {
		
		Swal({
			title: 'Tem certeza que deseja cancelar o processo de exclusão de dados?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Sim'
		  })
		  .then((result) => {
			if (result.value) {
				setIsButtonDisabled(true);
				fetch(`${localhost}/userpurge/toolbox`, {
					method: 'DELETE',
					headers: {'Content-Type': 'application/json', 'Authorization':  getToken()},
					body: JSON.stringify({'guid' : guid})
				})
				.then(response => {
					if(!response.ok){
						Swal({
						  position: 'center',
						  type: 'error',
						  title: 'Ocorreu algum erro ao cancelar o processo de exclusão de dados.',
						  showConfirmButton: false,
						  timer: 1500
						})	
						return
					}
					resolve(response)
				})
				.catch(error => console.log(error))
			}
		})
	})
}

const postUserpurgeEvent = async (guid,setIsButtonDisabled) => {
	return new Promise((resolve) => {
		
		Swal({
			title: 'Tem certeza que deseja iniciar processo de exclusão de dados?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Sim'
		  })
		  .then((result) => {
			if (result.value) {
				setIsButtonDisabled(true);
				fetch(`${localhost}/userpurge/toolbox`, {
					method: 'POST',
					headers: {'Content-Type': 'application/json', 'Authorization':  getToken()},
					body: JSON.stringify({'guid' : guid})
				})
				.then(response => {
					if(!response.ok){
						Swal({
						  position: 'center',
						  type: 'error',
						  title: 'Ocorreu algum erro ao cancelar o processo de exclusão de dados.',
						  showConfirmButton: false,
						  timer: 1500
						})	
						return
					}
					resolve(response)
				})
				.catch(error => console.log(error))
			}
		})
	})
}

export { getUserpurgeStatus, deleteUserpurgeEvent, postUserpurgeEvent };
