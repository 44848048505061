import React, { Component } from 'react';

// icons
import { ReactComponent as IconClose } from '../svgs/icon-close.svg';

// jquery
import $ from "jquery";

// styles
import '../styles/scss/components/lightbox.scss'

class Lightbox extends Component {
	constructor(props){
		super(props)

		let self = this

		this.closeLightbox = function (id){
			self.close(id)
		}

		this.bindEvents()
	}


	bindEvents() {
		$('body').on('click', '[data-lightbox]', function() {
			let id = $(this).attr('data-lightbox');
			$(`.lightbox#${id}`).toggleClass('active', true);
		})
	}

	close(id){
		$(`.lightbox#${id}`).toggleClass('active', false)
	}

	render() {
		let {id, title, content} = this.props;

		return(
			<div className="lightbox" id={id}>
				<div className="lightbox__overlay"></div>
				<div className="lightbox__container">
					<div className="lightbox__header">
						<h3 className="lightbox__title">{title}</h3>
						<button className="lightbox__close" onClick={() => this.close(id)}>
							<i className="icon icon-close">
								<IconClose />
							</i>
						</button>
					</div>
					<div className="lightbox__content">
						{content}
					</div>
				</div>
			</div>
		)
	}
}

export default Lightbox; 