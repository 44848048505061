
// Helpers
import $ from "jquery";

//
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { getToken, isLocalhost } from '../helpers';
import '../styles/scss/components/contas.scss';
import { ReactComponent as IconClose } from '../svgs/icon-close.svg';
import { ReactComponent as IconEdit } from '../svgs/icon-edit.svg';
import Details from './Details';
import Devices from './Devices';
import Lightbox from './Lightbox';
import Status from './Status';
import Degustacao from './Degustacao';


class Contas extends Component {
	constructor(props){
		super(props)
		this.localhost = isLocalhost()
		this.mpxid = ''
		this.state = {target: '', deviceActive: false, emailSearched: '', mpxid: '', email: '', account: {}}
	}

	async componentDidMount(){
		const {dataUsers} = this.props
		let accounts      = dataUsers.entries;
		await accounts.map((account, index) => {
			this.profiles(account.userId).then(profile => {
				this.state.accounts[index].profiles = profile['profiles'];
				return profile['profiles'];
			});

			this.last_login(account.userId).then(lastLogin => {
				this.state.accounts[index].last_login = lastLogin['last_login'];
		
				return lastLogin['last_login'];
				
			});
		});
	}
	openDetails(e) {
		let button = $(e.target);
		let deviceWrapper = button.parent().siblings('.contas__device, .contas__details, .contas__profile, .contas__salesforce, .contas__status, .contas__degustacao');

		if(!deviceWrapper.hasClass('active')){
			button.text('FECHAR DETALHES');
			deviceWrapper.toggleClass('active')

			return
		}

		button.text('VER DETALHES')
		deviceWrapper.toggleClass('active', false)
	}

	salesForce(email){
		let self = this
		let dataSf = [];
		
		let promiseSf = new Promise((resolve, reject) => {
			return fetch(`${self.localhost}/salesforce`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization':  getToken()
				}, 
				body: JSON.stringify({'email' : email})
			}).then(response => {
				if (response.status === 404) {
					$('.contas__salesforce-list').html('Não foram encontrados itens da cesta para este usuário.')
					return Promise.reject('error 404')
				}
				return response.json();
			}).then(result => {
				let data = result['data'];
				let data_cancelamento = '-';
				let format_data_cancelamento = '-';
				let format_hora_cancelamento = '-';
				data.map((data) => {
					if(data['data_hora_cancelamento'] !== '-' && data['data_hora_cancelamento'] !== ''){
						data_cancelamento        = data['data_hora_cancelamento'] && data['data_hora_cancelamento'].split(':')[0];
						let [data_cancelamento_y, data_cancelamento_m, data_cancelamento_d] = data_cancelamento.split('-')

						format_data_cancelamento = `${data_cancelamento_d.substr(0,2)}/${data_cancelamento_m}/${data_cancelamento_y}`;
						format_hora_cancelamento = data['data_hora_cancelamento'] && data['data_hora_cancelamento'].split('-')[2].substr(2, 15);
					} else {
						format_data_cancelamento = '';
						format_hora_cancelamento = ''
					}
					
					dataSf.push(`<li key="${data['id']}" class="contas__salesforce-item">
						<div class="contas__salesforce-agroup">
							<h3 class="contas__salesforce-title">Dados da conta</h3>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__statusassinatura">Status da assinatura</label>
								<span class="salesforce__statusassinatura">${data['status_assinatura']}</span>
							</div>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__comboouavulso">Produto</label>
								<span class="salesforce__comboouavulso">${data['combo_ou_avulso']}</span>
							</div>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__datacompra">Data da compra</label>
								<span class="salesforce__datacompra">${data['data_compra'].match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g)}</span>
							</div>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__statuscobranca">Status da cobrança</label>
								<span class="salesforce__statuscobranca">${data['status_cobranca']}</span>
							</div>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__formapagamento">Forma de pagamento</label>
								<span class="salesforce__formapagamento">${data['forma_pagamento']}</span>
							</div>
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__fimtrial">Foi Trial ? | Encerrou o trial?</label>
								<span class="salesforce__fimtrial">${data['periodo_trial_form'] !== "0.0" ? 'sim' : 'não'} | ${data['period_trial_form'] !== "0.0" && data['data_hora_cancelamento'] !== "" ? 'sim' : 'não'}</span>
							</div>
							
							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__data_conversao">Data da conversão</label>
								<span class="salesforce__data_conversao">${data['data_conversao'] !== '-' ? `${data['data_conversao'].split('-')[2]}/${data['data_conversao'].split('-')[1]}/${data['data_conversao'].split('-')[0]}` : '-'}</span>
							</div> 
						</div>
						<div class="contas__salesforce-agroup">
							<div class="contas__salesforce-itemgroup--cancelamento">
								<h3 class="contas__cancelamento-title">Cancelamento</h3>
								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__datahoracancelamento">Data</label>
									<span class="salesforce__datahoracancelamento">
										${format_data_cancelamento} - ${format_hora_cancelamento}
									</span>
								</div>
								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__motivocancelamento">Motivo</label>
									<span class="salesforce__motivocancelamento">${data['motivo_cancelamento']}</span>
								</div>
								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__tipocancelamento">Tipo</label>
									<span class="salesfroce__tipocancelamento">${data['tipo_cancelamento']}</span>
								</div>
								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__canalcancelamento">Canal</label>
									<span class="salesforce__canalcancelamento">${data['canal_cancelamento']}</span>
								</div>
							</div>
						</div>

						<div class="contas__salesforce-agroup">
							<div class="contas__salesforce-itemgroup--suspensao">
								<h3 class="contas__suspensao-title">Suspensão</h3>

								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__data_inadimplencia">Data da inadimplência</label>
									<span class="salesforce__data_inadimplencia">${data['data_inadimplencia']}</span>
								</div>

								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__data_suspensao">Data da suspensão</label>
									<span class="salesforce__data_suspensao">${data['data_suspensao']}</span>
								</div>
								
								<div class="contas__salesforce-itemgroup">
									<label for="salesforce__motivo_suspensao">Motivo da suspensão</label>
									<span class="salesforce__motivo_suspensao">${data['motivo_suspensao']}</span>
								</div>
							</div>

							<div class="contas__salesforce-itemgroup">
								<label for="salesforce__cancelamento_final_ciclo">Final do ciclo</label>
								<span>${data['cancelamento_final_ciclo'] !== '-' ? `${data['cancelamento_final_ciclo'].split('-')[2]}/${data['cancelamento_final_ciclo'].split('-')[1]}/${data['cancelamento_final_ciclo'].split('-')[0]}` : '-'}</span>
							</div>
						</div>
					</li>`) });
				
				resolve()

				return data;
			}).catch(e => {
				console.log('deu algum erro', e)
				reject()
			})
		})



		promiseSf.then(() => {
			if(!dataSf.length){
				$('.contas__salesforce-list').html('Não existem dados registrados no Salesforce para este usuário.')

				return
			}
			
			$('.contas__salesforce-list').html(dataSf.join(''))
		}).catch(e => {
			console.log('error: ', e)
		})
	}
	last_login(guid){
		let promiseLastLogin = fetch(`${this.localhost}/massive/lastlogin`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization':  getToken()
				}, 
				body: JSON.stringify({'guid' : guid})
			}).then(response => {
				if (response.status === 404) {
					return Promise.reject('error 404')
				}
				return response.json();
			})
		return promiseLastLogin
	}

	profiles(guid){
		let promiseProfiles= fetch(`${this.localhost}/massive/profile`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization':  getToken()
				}, 
				body: JSON.stringify({'guid' : guid})
			}).then(response => {
				if (response.status === 404) {
					return Promise.reject('error 404')
				}
				response = response.json();
				return response;
			})
		return promiseProfiles;
	}

	invalidate(urn){ // this method return button to invalidate account by urn
		let self = this
		let isN1 = window.localStorage.getItem('grupo') === 'N1' ? false : true

		if(!isN1) return
		
		function invalidUser(){
			
			Swal({
			  title: 'Tem certeza que deseja invalidar este usuário?',
			  type: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  cancelButtonText: 'Cancelar',
			  confirmButtonText: 'Sim'
			}).then((result) => {
			 	if(!result.value || !urn) return

			 	fetch(`${self.localhost}/massive/device/invalidate`, {
					 method: 'POST',
					 headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization':  getToken()
					},
					body: JSON.stringify({'urn': urn})
			 	}).then(response => {
			 		if(!response.ok) return

			 		Swal({
					  position: 'center',
					  type: 'success',
					  title: 'Usuário invalidado com sucesso!',
					  showConfirmButton: false,
					  timer: 1500
					})
			 	})
			})
		}

		return (
			<button className="contas__button-invalidate" onClick={invalidUser}>
				<i className="icon icon-invalidate"><IconClose /></i>
			</button>
		)
	}

	validateEmail(email){
	    var re = /\S+@\S+\.\S+/;
	    return re.test(email);
	}

	updateEmailAction(event){
		event.preventDefault();

		let mpxid = this.mpxid;
		let email = event.target[1].value;
		let button = $(event.target).find('[type="submit"]');

		button.val('atualizando ...')

		
		if(!this.validateEmail(email)){
			button.val('salvar')
			Swal({
				  position: 'center',
				  type: 'error',
				  title: 'E-mail inválido, por favor digite um email válido.',
				  showConfirmButton: false,
				  timer: 1500
				})

			return
		}


		fetch(`${this.localhost}/comcast/emailupdate`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({'email': email, 'mpxid': mpxid})
		}).then((response)=> response.json()).then(data => {
				button.val('salvar')

				if(!data.success) {
					Swal({
					  position: 'center',
					  type: 'error',
					  title: 'Ocorreu algum erro ao atualizar este e-mail.',
					  showConfirmButton: false,
					  timer: 1500
					})

					return
				}

				Swal({
				  position: 'center',
				  type: 'success',
				  title: 'E-mail atualizado com sucesso!',
				  showConfirmButton: false,
				  timer: 1500
				}).then(() => {
					$('#update_email_user').removeClass('active');
				})

			}).catch(e => {
				console.warn('error-> ',e)
			})
	}

	updateStatusAction(event){
		event.preventDefault();
		let guid = this.state.guid;
		let mpxid = this.mpxid;
		let status_new = event.target[1].value;
		let date_to_be_executed = event.target[2].value;
		let button = $(event.target).find('[type="submit"]');

		if(!status_new){
			button.val('salvar')
			Swal({
				  position: 'center',
				  type: 'error',
				  title: 'Escolha um status válido',
				  showConfirmButton: false,
				  timer: 1500
				})

			return
		}


		fetch(`${this.localhost}/comcast/statusupdate`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization':  getToken()
			},
			body: JSON.stringify({
									'orderStatus': status_new, 
									'mpxid': mpxid,
									'guid': guid,
									"toBeExecutedDate": date_to_be_executed
								})
			}).then(response => {
				if(response.status !== 200) {
					Swal({
					  position: 'center',
					  type: 'error',
					  title: 'Ocorreu um erro ao atualizar o status deste usuário.',
					  showConfirmButton: false,
					  timer: 1500
					})

					return
				}
				button.val('salvar')
				
				

				Swal({
				  position: 'center',
				  type: 'success',
				  title: 'Status atualizado com sucesso!',
				  showConfirmButton: false,
				  timer: 1500
				}).then(() => {
					$('#update_status_user').removeClass('active');
				})
			})
	}

	//set the state value based on select option value
	hideField = (e) =>{
		if (e.target.value == 99 || e.target.value == 5) {
			this.setState({hideDateField: "contas__updateStatus-date-show" });
		} else {
			this.setState({hideDateField: "contas__updateStatus-date-hide" });
		}
	}   

	updateStatusContent(){
		let account_status = this.state.account_status;
		let classNameDateField = this.state.hideDateField ? this.state.hideDateField : "contas__updateStatus-date-hide";
		
		return (
			<form className="contas__updateStatus" onSubmit={e => this.updateStatusAction(e)}>
				<div className="contas__updateStatus-group">
					<label>Status antigo</label>
					<input className="contas__updateStatus-input" disabled={true} placeholder={account_status} type="text" name="email_old" />
				</div>
				<div className="contas__updateStatus-group">
					<label>Status novo</label>
					<select className="contas__updateStatus-input" onChange={this.hideField}>
						<option value="4">Ativo</option>
						<option value="2">Suspenso</option>
						<option value="3">Cancelado</option>
						<option value="5">Em cancelamento</option>
						<option value="99">Trial</option>
					</select>
				</div>
				<div className={classNameDateField}>
					<label>Data para execução</label>
					<input className="contas__updateStatus-input" type="date"  name="date_to_be_executed" />
				</div>
				<input className="contas__updateStatus-button" type="submit" value="salvar" />
			</form>
		)
	}

	updateEmailContent(){
		let emailSearched = this.state.emailSearched;
		
		return (
			<form className="contas__updateEmail" onSubmit={e => this.updateEmailAction(e)}>
				<div className="contas__updateEmail-group">
					<label>E-mail antigo</label>
					<input className="contas__updateEmail-input" disabled={true} placeholder={emailSearched} type="text" name="email_old" />
				</div>
				<div className="contas__updateEmail-group">
					<label>E-mail novo</label>
					<input className="contas__updateEmail-input"  type="text" name="email_new"  />
				</div>
				<input className="contas__updateEmail-button" type="submit" value="salvar" />
			</form>
		)
	}

	updateMpxid(event, id, email,account_status, guid){
		event.preventDefault()
		this.setState({emailSearched: email, account_status: account_status, guid: guid})
		this.mpxid = id

	}

	list(){
		const {dataUsers} = this.props
		let isN1 = window.localStorage.getItem('grupo') === 'N1'
		let updateAction = false;
		if (!isN1){
			updateAction = true;
		}

		let accounts      = dataUsers.entries;
		this.state.accounts = accounts;

		return accounts.map((account, index) => {
			let account_status = '';
			let statusReferenceDate = '';
			let isDegustacao = account.tele$operator === "Telecine Degustação"

			if(account.tele$authMethod === 'globo'){
				var { email, id, userId: urn, tele$authMethod: method } = account;
				
				account_status = account.tele$subscriptionStatus;
				
				if (account.tele$subscriptionStatus == 'Active'){
					account_status = 'Ativo';
				} else if (account.tele$subscriptionStatus == 'Scheduled Cancel'){
					account_status = 'Em cancelamento';
				} else if (account.tele$subscriptionStatus == 'Suspension by Payment Issues'){
					account_status = 'Suspenso';
				} else if (account.tele$subscriptionStatus == 'Cancelled'){
					account_status = 'Cancelado';
				} else if (account.tele$subscriptionStatus == 'Payment Issues communication'){
					account_status = 'Pagamento pendente';
				}
				
				if (account.tele$statusReferenceDate){ 
					if (account.tele$subscriptionStatus == 'Active'){
						account_status = 'Trial';
					}
					statusReferenceDate = `Fim em ${account.tele$statusReferenceDate}`;
				}
					
			} else {
				var {email,
					id,
					tele$authMethod: method, 
					tele$operator: operator, 
					tele$toolboxCustomerId: customerId} = account;
			}

			let profiles = [];
			let data = account.profiles
			data && data.length && $('.js-count-profiles').html(data.length);
			data && data.length && data.map((prof) => {
				profiles.push(<div key={prof.profileId} className="perfis__item" id={prof.profileId}>{!prof.isPrimary && !prof.isKidsProfile ? <b className="perfis__item-type perfis__item-type--secondary">Perfil secundário</b> : '' } {prof.isPrimary ? <b className="perfis__item-type perfis__item-type--main">Perfil principal</b> : ''} {prof.isKidsProfile ? <b className="perfis__item-type perfis__item-type--kids">Perfil kids</b> : ''} {prof.profileName} ({prof.profileEmail})</div>);
			});
			return (
				<div className="contas__item" key={index} data-index={index+1}>
					<div className="contas__group contas__group--invalidate">
						{this.invalidate('urn:toolbox:' + customerId)}
					</div>
					<div className="contas__group contas__group--email">
						<label>
							Email	
							{updateAction && ( 
								<button className="contas__button-editemail" data-lightbox="update_email_user" onClick={(e) => this.updateMpxid(e, id, account.email, account_status, account.guid)}> 
									<i className="icon icon-editemail"><IconEdit /></i>
								</button>
							)}
						</label>
						<span>{email}</span>
					</div>
					<div className="contas__group contas__group--operator">
						<label>Operadora</label>
						<span>{(method !== 'globo') ? operator : method}</span>
					</div>
					{account.last_login && ( 
					<div className="contas__group">
						<label>Data do último acesso:</label>
						{account.last_login}
					</div>)}
					<div className="contas__group contas__group--action">
						{method !== 'globo' ? <button className="contas__button-details" onClick={(e) => this.openDetails(e)}
						data-email={email} data-globo="false" data-urn={customerId}>Ver detalhes</button> : <button className="contas__button-details"  onClick={(e) => this.openDetails(e)}  
						data-email={email} data-globo="true" data-urn={urn}>Ver detalhes</button>}
					</div>

					
					<div className="contas__profile contas__profile--urn">
						<h3 className="contas__profile-title">URN</h3>
						<span>{method !== 'globo' ? 'urn:toolbox:' + customerId : urn }</span>
					</div>
					{profiles && ( 
					<div className="contas__profile">
						<h3 className="contas__profile-title">Perfis</h3>
						{profiles}
					</div>)}
					{isDegustacao && (
					<div className="contas__degustacao">
						<Degustacao subscriberId={account.tele$subscriberId} guid={'urn:toolbox:' + customerId} email={email}/>
					</div>
					)
					}
					<div className="contas__status">
						<h3 className="contas__status-title">Andamento de exclusão da conta</h3>
						<Status guid={method !== 'globo' ? 'urn:toolbox:' + customerId : account.guid } method={method} />
					</div>
					{method === 'globo' && (<div className="contas__profile contas__profile--status">
						<h3 className="contas__profile-title">Status MPX  
							<button className="contas__button-editstatus" data-lightbox="update_status_user" onClick={(e) => this.updateMpxid(e, id, account.email, account_status, account.guid)}> 
								<i className="icon icon-editemail"><IconEdit /></i>
							</button>
						</h3>
					<span>{account_status} {statusReferenceDate && ( `- ${statusReferenceDate}`)}</span>
					</div> )}


					{method === 'globo' ? <div className="contas__salesforce">
						<h3 className="contas__salesforce-title">Dados Salesforce</h3>
						<ul className="contas__salesforce-list">Listando... {this.salesForce(email)}</ul>
					</div> : ''}
					
					<div className="contas__device">
						{method !== 'globo' ? <Devices target={this.state.target} urn={customerId}  /> : <Devices target={this.state.target} urn={urn}  /> }
					</div>
					{/*-- Conviva-- */}
					<div className="contas__details">
						{method !== 'globo' ? <Details target={this.state.target} urn={customerId}  /> : <Details target={this.state.target} urn={urn}  /> }
					</div>
				</div>
			)
		})
	}

	render() {
		
		return(
			<div className="contas">
				{this.list()}
				
				<Lightbox id="update_email_user" title="Atualizar Email" content={this.updateEmailContent()}/>
				<Lightbox id="update_status_user" title="Atualizar Status MPX" content={this.updateStatusContent()}/>
			</div>
		)
	}
}


export default Contas;