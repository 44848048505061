import React from 'react';
import '../styles/scss/error404.scss';


class Error404 extends React.Component {

	render(){
		return (
			<div className="error404">
				Página não encontrada :(
			</div>
		)
	}
}


export default Error404;