// Helpers
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { getToken, isLocalhost } from '../helpers';
import '../styles/scss/components/ingresso.scss';


class Ingresso extends Component {
    constructor(props){
        super(props);

        this.localhost = isLocalhost();

        this.state = {
            enable: false,
            isVerified: true,
            motivos: [],
            apto: ''
        }
    }

    async checkPermissionUser(users){
        let config = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':  getToken()
        };

        let response = await fetch(`${this.localhost}/ingressocom/verificar/apto`, {method: 'POST',headers: config, body: JSON.stringify({'entries': users})})
        let data = await response.json()
        return data
    }

    async verifyUserEnable(){
        let {dataUsers} = this.props;
        let cpf = (dataUsers && dataUsers.entries[0]) ? dataUsers.entries[0]['tele$cpf'] : false;

        if(!cpf)
            console.log('cpf não existe para esta conta...')
        
        let permission = await this.checkPermissionUser(dataUsers.entries)
        const { apto } = permission;
        let { motivo } = permission;
        
        if(!apto){
            const motivos = motivo || ['Usuário não encontrado'];
            this.setState({ motivos }) 
        } else {
            // set user apto true
            this.setState({apto: true})
            
            // Start verify
            fetch(`${this.localhost}/ingressocom/verificar`, {
            method: 'POST',headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':  getToken()
                },
                body: JSON.stringify({'cpf': cpf})
            }).then(response => {
                
            return response.json()
            }).then(data => {
                if(data.success === true){
                    this.setState({
                        enable: true
                    });

                    return
                }

                this.setState({
                    enable: false
                })
            }).catch(() => {
                this.setState({
                    enable: false
                })
            })
        }
    }

    componentWillReceiveProps(){
        this.verifyUserEnable();
    }

    enableClient(event){
        const self = this;
        let isChecked = event.target.checked;
        let {dataUsers} = this.props;
        let cpf = dataUsers.entries[0]['tele$cpf'];
        
        if(isChecked){
            // post
            fetch(`${this.localhost}/ingressocom/liberar`, {
                method: 'POST',headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
					'Authorization':  getToken()
                },
                body: JSON.stringify({'cpf': cpf})
            }).then(response =>  {
                return response.json()
             }).then(data => {
                if(data.success !== true){
                    alert('não foi possível atualizar cliente');

                    this.setState({
                        enable: false
                    });

                    return
                }


                // Cliente habilitado
                Swal({
                    position: 'center',
                    type: 'success',
                    title: 'Cliente habilitado com sucesso!',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    self.setState({
                        enable: true
                    })
                })

                
            }).catch(() => {
                this.setState({
                    enable: false
                })
            })

            return
        }

        this.setState({
            enable: true
        })
    }

    render(){
        let {enable, isVerified} = this.state;

        return (
            <div className="ingresso ingresso__content">
                <h3 className="ingresso__title">Ingresso</h3>
                
                { this.state.apto ? 
                    <div className="ingresso__checked">Cliente atende os requisitos da promoção.</div> :
                    <div className="ingresso__unchecked">Cliente não atende os requisitos da promoção.</div>
                }
                
                
                <div className="ingresso__input-group">
                    {this.state.motivos.length ? 
                        <div className="ingresso__mensagens">
                            {this.state.motivos.map((motivo, index) => {
                                return (<div className="ingresso__mensagens-item" key={index}>{motivo}</div>)
                            })}
                        </div> : null
                    }

                </div>
                <div className="ingresso__enable">
                    <input id="enableClient" type="checkbox"
                        name="enable_ingresso"
                        checked={enable}
                        disabled={enable}
                        onChange={this.enableClient.bind(this)}/>
                    <label>Habilitar</label>
                </div>
            </div>
        )
    }
}

export default Ingresso;