import $ from "jquery";
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { authMassive, deleteMassive } from '../apis/Massive';
import { isLocalhost } from '../helpers';
import '../styles/scss/components/contas.scss';
import '../styles/scss/components/devices.scss';


class Devices extends Component {
	constructor(props){
		super(props)
		this.state = {data: [], count: 0}
		this.localhost = isLocalhost();

		this.delete()
	}

	delete() {
		$('body').on('click', '.js-delete-device', function() {
			let deviceId = $(this).data('device');
			let button = $(this);

			Swal({
			  title: 'Tem certeza que deseja remover este dispositivo?',
			  type: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  cancelButtonText: 'Cancelar',
			  confirmButtonText: 'Sim'
			}).then((result) => {
			  if (result.value) {
			  	deleteMassive(deviceId).then(() => {
			  		Swal({
					  position: 'center',
					  type: 'success',
					  title: 'Dispositivo excluído com sucesso!',
					  showConfirmButton: false,
					  timer: 1500
					}).then(() => {
						button.closest('.device__item').remove();
						$('.device__count').attr('data-count', $('.device__count').attr('data-count') - 1);

					})
			  	})
			  }
			})
		})
		
	}

	componentDidMount(){
		let {target, urn} = this.props;
		
		let button = $(`[data-urn="${urn}"]`);
		let isGlobo = button.attr('data-globo');
		let devices = [];

	 	$('body').toggleClass('loading');
		if(button.hasClass('active')){
			$('body').toggleClass('loading', false);
			button.text('VER DETALHES').removeClass('active');

			return
		}

		authMassive(urn, isGlobo).then((data) => {
			if(!data.devices) return

			function dataAtualFormatada(date){
			    var data = new Date(date),
			        dia  = data.getDate().toString().padStart(2, '0'),
			        mes  = (data.getMonth()+1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
			        ano  = data.getFullYear();
			    return dia+"/"+mes+"/"+ano;
			}


			data.devices.length && this.setState({count: data.devices.length})
			data.devices.map(device => {
				let {deviceId, deviceType, label, serialNumber, dateOfRegistration} = device;

				let markup = `<div class="device__item">
								<div class="device__group">
									<label for="">Tipo</label>
									<span class="device__type">${deviceType}</span>
								</div>
								<div class="device__group">
									<label for="">Nome</label>
									<span class="device__label">${label}</span>
								</div>
								<div class="device__group">
									<label for="">Número de Série</label>
									<span class="device__serie">${serialNumber}</span>
								</div>
								<div class="device__group">
									<label for="">Data de Registro</label>
									<span class="device__date">${dataAtualFormatada(dateOfRegistration)}</span>
								</div>
								<button data-device="${deviceId}" class="device__delete js-delete-device">excluir</button>
							</div>`;
				devices.push(markup)
				return markup;
			}).join('')

			this.setState({data: devices.join('')})
			$('body').toggleClass('loading', false);
		}).catch((e) => {
			this.setState({data: '<div class="no-content">Nenhum dispositivo foi encontrado.</div>'})
			$('body').toggleClass('loading', false);
		})
	}

	count() {
		return (<h3 className="device__count" data-count={this.state.count}>Dispositivos</h3>)
	}

	render() {
		return (
			<div>
				<div className="device__header">{this.count()}</div>
				<div className="device" dangerouslySetInnerHTML={{__html: this.state.data}} />	
			</div>
		)
	}
}


export default Devices;