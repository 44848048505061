import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import Error404 from './components/Error404';
import Login from './components/Login';
// Helpers
// Components
import Welcome from './components/Welcome';
import Loading from './images/loading.gif';
import './styles/scss/App.scss';
require('dotenv').config();

class App extends Component {
  render(){
    return(
      <Router>
        <div>
          <div className="loading">
            <img className="loading__gif" src={Loading}></img>
          </div>
          <Switch>
            <Route exact path='/' component={Welcome} />
            <Route path='/login' component={Login} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/admin' component={Admin} />

            
            <Route component={Error404} />
          </Switch>
        </div>
      </Router>
    )  
  }
}

export default App;
