import $ from "jquery";
import React, { Component } from 'react';
import { isLocalhost } from '../helpers';
import logoTelecine from '../images/logo-telecine.png';
import '../styles/scss/login.scss';
import { ReactComponent as Logo } from '../svgs/logo.svg';


var CryptoJS = require("crypto-js");

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: {}, 
      msg: '',
      logged: false
    };

    this.localhost = isLocalhost();
    this.authorization = this.authorization.bind(this);
  }

  handleAuth(propertyName, event){
    let auth = this.state.auth;
    auth[propertyName] = event.target.value;

    this.setState({auth: auth, msg: ''});
  }

 

  login(admin, email, token, grupo){
    
    if(admin === 1) localStorage.setItem('admin', true);
    localStorage.setItem('user', email);
    localStorage.setItem('token', token);
    localStorage.setItem('grupo', grupo);
    localStorage.setItem('logged', true);

    this.props.history.push({
      pathname: '/dashboard',
      state: { logged: this.state.logged }
    });
    return
  
  }

  authorization(e){
    let self = this;
    e.preventDefault()

    if(!Object.keys(this.state.auth).length){
      this.setState({msg: 'Preencha o email e a senha corretamente!'})
      return
    }

    const {email, password} = self.state.auth;
    $.ajax({
      url: `${this.localhost}/user/auth`,
      type: 'POST',
      crossDomain: true,
      contentType: 'application/json',
      data: JSON.stringify({'email': email, 'password': CryptoJS.SHA256(password).toString()}),
      success: function(response) {
        if(!response.success){
          self.setState({msg: 'Usuário ou Senha incorretos'});
          localStorage.removeItem('token');

          return
        }  

        self.setState({logged: true});
        self.login(response['user']['admin'], response['user']['email'], response['token'], response['user']['grupo'])
      }
    })
  }

  render() {
    
    return (
      <div className="login">
        <figure className="login__logo">
            <Logo />
        </figure>
        <div className="login__box">
          <figure className="login__logo-telecine">
            <img src={logoTelecine} alt="Logo Telecine" />
          </figure>
          <form className="login__form" onSubmit={this.authorization} >
            <input className="login__input" value={this.state.email} onChange={this.handleAuth.bind(this, 'email')} autoComplete="email" placeholder="Email" type="text" name="email"/>
            <input className="login__input" value={this.state.password} onChange={this.handleAuth.bind(this, 'password')}  placeholder="Senha" type="password" name="password"/>
            <button className="login__submit" type="submit">Entrar</button>
            {this.state.msg && <div className="msg error">{this.state.msg}</div>}
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
