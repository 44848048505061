import $ from "jquery";
import React, { Component } from 'react';
import '../styles/scss/dashboard.scss';
import { ReactComponent as IconTicket } from '../svgs/icon-tickets.svg';
import { ReactComponent as IconTvod } from '../svgs/icon-tvod.svg';
import { ReactComponent as IconUser } from '../svgs/icon-users.svg';
import Contas from './Contas';
import Ingresso from './Ingresso';
import Tickets from './Tickets';
import Tvod from './Tvod';


class Tabs extends Component {
	open(event) {
		let button = event.target;
		let tab = $(button).attr('data-tab');
		let content = $('.tabs__content');

		$(button).toggleClass('active').siblings().toggleClass('active', false);
		content.find(`#${tab}`).toggleClass('active').siblings().toggleClass('active', false);
	}


	render() {
		const {users, loading, tickets, tvod} = this.props;
		
		return (
			
			<div className="tabs">
				<div className="tabs__header">
					<button className="tabs__button active" data-tab="contas" onClick={this.open.bind(this)}>
						<i className="tabs__icon tabs__icon-users">
							<IconUser/>
						</i> 
						<span>Contas</span>
					</button>
					<button className="tabs__button" data-tab="tickets" onClick={this.open.bind(this)}>
						<i className="tabs__icon tabs__icon-tickets">
							<IconTicket/>
						</i>	
						<span>Tickets</span>
					</button>
					<button className="tabs__button" data-tab="ingresso" onClick={this.open.bind(this)}>
						<i className="tabs__icon tabs__icon-tickets">
							<IconTicket/>
						</i>	
						<span>Ingresso</span>
					</button>
					{
						(!loading && tvod && tvod.length > 0) && (
							<button className="tabs__button" data-tab="tvod" onClick={this.open.bind(this)}>
								<i className="tabs__icon tabs__icon-tvod">
									<IconTvod/>
								</i>	
								<span>TVOD</span>
							</button>
						)
					}
				</div>
				<div className="tabs__content">
					<div id="contas" className="active">
						{(loading) ? <div className="loading__bars"><div></div><div></div><div></div><div></div></div> : 
							(!loading && users && users.entries.length) ? <Contas dataUsers={users}/> : <div className="no-content">Nenhum usuário foi encontrado.</div>
						}
					</div>
					<div id="tickets" className="contas__ticket">
						{(loading) ? <div className="loading__bars"><div></div><div></div><div></div><div></div></div> : 
							(!loading && tickets) ? <Tickets tickets={tickets}/> : <div className="no-content">Nenhum ticket foi encontrado.</div>
						}
					</div>
					<div id="ingresso">
						{loading ? <div className="loading__bars"><div></div><div></div><div></div><div></div></div>  : 
							(!loading) ? <Ingresso dataUsers={users} /> : <div className="no-content">Nenhum ticket foi encontrado.</div>
						}
					</div>
					{
						(!loading && tvod && tvod.length > 0) && (
							<div id="tvod">
								{
									loading ? (
										<div className="loading__bars"><div></div><div></div><div></div><div></div></div> 
									) : (
										<Tvod movies={tvod} />
									)
								}
							</div>
						)
					}
				</div>
			</div>
		)
	}	
}

export default Tabs;