import $ from "jquery";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { accountComcast } from '../apis/Comcast';
import { getConsumptionTvod } from '../apis/Tvod';
import { getTickets } from '../apis/Zendesk';
import { isLocalhost } from '../helpers';
import logoTelecinePortal from '../images/logo-telecine-portal.png';
//styles
import '../styles/scss/dashboard.scss';
import { ReactComponent as IconSearch } from '../svgs/icon-search.svg';
// Components
import Tabs from './Tabs';


class Dashboard extends Component {
	constructor(props){
		super(props)
		this.state = {
			users: [],
			tvod: [],
			email: window.localStorage.getItem('user'),
			isAdmin: window.localStorage.getItem('grupo') === 'admin' ? true : false,
			tickets: [],
			logged: (this.props && this.props.location.state) ? this.props.location.state.logged : false
		}

		this.localhost = isLocalhost();
	}

	componentDidMount(){

		if(!window.localStorage.getItem('logged')){
			this.props.history.push('/login');
		}

      	this.bindEvents()
	}

	logout(){
		Swal({
		  title: 'Tem certeza que deseja sair?',
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  cancelButtonText: 'Cancelar',
		  confirmButtonText: 'Sim, desejo sair!'
		}).then((result) => {
		  if (result.value) {
		    this.props.history.push('/')
			localStorage.clear()
		  }
		})
	}

	handleEmail(e){
		this.setState({email: e.target.value});
	}

	search(e) {
		e.preventDefault();
		let input = e.target;

		let email = $(input).find('.search__input').val();

		// loading
		$('body').toggleClass('loading');		
		$('[data-tab]').removeClass('active').eq(0).trigger('click').addClass('active');

		this.setState({ users: [], loading: true });

		try {
			accountComcast(email).then(async data => {
				if (data["entries"].length){
					let emails = [...new Set(data["entries"].map(x => x["email"]))]
					getTickets(emails).then((response) => {
						if(!response.results) return

						this.setState({tickets: response.results});
					});
				} else {
					getTickets([email]).then((response) => {
						if(!response.results) return

						this.setState({tickets: response.results});
					});
				}

				this.setState({
					users: data,
					loading: false
				});
				
				await getConsumptionTvod(data["entries"][0]).then((response) => {
					if(!response.movies) return
					this.setState({tvod: response.movies});
				});

				$('body').toggleClass('loading', false);
				$('.tabs__content').find('#contas').addClass('active')
			}).catch(error => {
				console.log('Entre em contato com o suporte.', error);
				$('body').toggleClass('loading', false);
				$('.tabs__content').find('#contas').addClass('active')
				// return
			});
		} catch(error) {
			console.log(error)
		}
	}

	bindEvents(){
		let whileNotExist = setInterval(function() {
			if($('.js-open-tabs')[0]){

				$('.tabs__content').on('click', '.js-open-tabs', function(){
					let header = $(this);
					let content = $(this).siblings('.session__item-content');

					header.toggleClass('active')
					content.toggleClass('active')
				})

				clearInterval(whileNotExist)
			}	
		}, 200)
	}

	render(){
		let user = localStorage && localStorage.getItem('user');
	
		return (
			<div className="dashboard">
				<header className="header dashboard__header">
					<div className="header__container container">
						<figure className="header__logo">
							<img src={logoTelecinePortal} alt="Logo Telecine Portal do Agente"/>
						</figure>
						<div className="header__welcome">
							<span className="welcome__title">Bem-vindo {user}</span>
						</div>
						<div className="header__search">
							<form className="search" onSubmit={this.search.bind(this)}>
								<label className="search__label">Pesquisar por email</label>
								<div className="search__group">
									<input className="search__input" type="search" placeholder="Digite o email"/>
									<button className="search__button"><IconSearch /></button>	
								</div>
							</form>
						</div>
						{(this.state.isAdmin) ? 
							<div className="header__action">
								<Link to={{pathname: '/admin', state: {logged: this.state.logged, isAdmin: true} }} className="header__button header__button--dashboard">Acessar Admin</Link>
							</div> : ''
						}
						<div className="header__action-zendesk">
							<a className="header__button--newticket" href="https://operacaotelecine.zendesk.com/hc/pt-br/requests/new" target="_blank" rel="noopener noreferrer">Criar Ticket</a>
						</div>
						<div className="header__logout">
							<div className="logout">
								<button className="logout__button" onClick={this.logout.bind(this)}>sair</button>
							</div>
						</div>	
					</div>
				</header>
				<main className="dashboard__content">
					<div className="dashboard__container container">
						{/*-- Tabs-- */}
						<Tabs
							users={this.state.users}
							loading={this.state.loading}
							tickets={this.state.tickets}
							tvod={this.state.tvod}
						/>
					</div>
				</main>
			</div>
		)
	}
}


export default Dashboard;